<template>
  <div class="container">
    <header>
      <div>
        <b-navbar toggleable="lg" type="light" style="background-color: #fdf7f2 !important;">
          <b-navbar-brand to="/"><b-img src="logo.png" fluid width="50px;"></b-img></b-navbar-brand>            
          <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
          <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav>
              <b-nav-item to="/">Home</b-nav-item>
              <b-nav-item to="/report-generator">Assistant</b-nav-item>
            </b-navbar-nav>
          </b-collapse>
        </b-navbar>
      </div>
    </header>
    
    <main class="bg-light p-3 vh-90">
      <router-view></router-view>
    </main>
  </div>
</template>

<script>

export default {
  name: 'App',
}
</script>
