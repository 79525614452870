<template>
  <div>
    <b-card class="mb-4" title="Welcome">
      <p>
        Hello {{ userDisplayName }}, I'm here to help you better understand your congregation.
        Ask me anything! If it's in the data, I'll do my best to get you the right answers.
      </p>

      <div v-if="!loadingSuggestions && currentSuggestion">
        <p>
          If you're not sure where to start, try one of these suggestions:
        </p>
        <p class="prompt-suggestion">
          <transition name="slide-fade" mode="out-in">
            <div
              :key="currentSuggestion"
              class="mb-2"
            >
              {{ currentSuggestion }}
            </div>
          </transition>
          <b-button
            size="sm"
            variant="success"
            @click="submitSuggestion(currentSuggestion)"
          >
          Try it
          </b-button>
        </p>
    </div>
    </b-card>
    
    <!-- <div>
      <b-form-select
        v-model="selectedDaabaseId"
        :options="databases"
        class="mb-3"
        value-field="id"
        text-field="name"
      ></b-form-select>
    </div> -->
    <b-form-textarea
      id="textarea"
      v-model="message"
      placeholder="What can I do for you?"
      rows="3"
      max-rows="6"
      @keydown.enter.prevent="generateReport()"
    ></b-form-textarea>

    <b-button
      variant="success"
      class="mt-3 mb-3"
      @click="generateReport()"
    >Submit <b-icon-chat-quote></b-icon-chat-quote>
    </b-button>
    
    <div v-if="answerLoading" >
      <b-spinner class="align-middle"></b-spinner>
    </div>

    <div v-if="errorMessage">
      <b-card>
        {{ errorMessage }}
      </b-card>
    </div>

    <div v-if="answerExplanation">
      <b-card>
        {{ answerExplanation }}
      </b-card>
    </div>

    <div v-if="reportData.length > 0">
      <b-card>
        <div>
          <b-input v-model="itemsPerPage" style="max-width:60px; display:inline"></b-input>
          results per page
        </div>

        <b-pagination
          v-if="reportData.length > itemsPerPage"
          v-model="currentPage"
          :total-rows="reportData.length"
          :per-page="itemsPerPage"
          aria-controls="reportTable"
          class="mt-3"
        ></b-pagination>

        <p>{{ reportData.length }} total result(s)</p>

        <b-button
          v-b-tooltip.hover title="Download results as CSV"
          @click="downloadCsv"
        >
          <b-icon-download></b-icon-download>
        </b-button>

        <b-button
          v-if="reportDataHasEmails"
          class="mx-4"
          v-b-tooltip.hover title="Copy email addresses to the clipboard"
          @click="copyEmails"
        >
          <b-icon-envelope></b-icon-envelope>
        </b-button>
        <b-alert
          :show="emailsCopiedDismissCountDown"
          fade
          variant="warning"
          class="mt-3"
          @dismiss-count-down="emailsCopiedCountDownChanged"
        >
          Emails copied to clipboard
        </b-alert>
        <b-table
          id="reportTable"
          striped
          hover
          sticky-header="80vh"
          :responsive="true"
          :per-page="itemsPerPage"
          :current-page="currentPage"
          :items="reportData"
          class="mt-3"
        >
        </b-table>
      </b-card>
    </div>
  </div>
</template>

<script>
export default ({
  name: 'ReportGenerator',
  data() {
    return {
      databases: [],
      selectedDatabaseId: "",
      message: "",
      errorMessage: "",
      answerExplanation: "",
      reportData: [],
      answerLoading: false,
      itemsPerPage: 100,
      currentPage: 1,
      suggestions: [],
      currentSuggestion: "",
      suggestionCycleSeconds: 4,
      userDisplayName: '',
      loadingSuggestions: true,
      currentSuggestionIndex: 0,
      emailsCopiedDismissCountDown: 0
    }
  },
  created() {
    if(!localStorage.signedIn){
      this.$router.replace('/signin')
    } else{
      this.userDisplayName = localStorage.userDisplayName
      this.fetchDatabases()
    }
  },
  computed: {
    reportDataHasEmails() {
      return this.reportData.length > 0 && "email" in this.reportData[0]
    }
  },
  methods: {
    fetchDatabases() {
      this.secured.post('/databases')
        .then(response => {
          this.databases = response.data
          this.selectedDatabaseId = this.databases[0].id
          this.fetchSuggestions()
        })
        .catch(error => console.log(error))      
    },
    fetchSuggestions(){
      this.loadingSuggestions = true
      const data = {
        db_id: this.selectedDatabaseId
      }

      this.secured.post('/db_chat/suggested_prompts', data)
        .then(response => {
          if(response.data.length > 0) {
            this.suggestions = response.data
            this.nextSuggestion(0)
          } else {
            console.log("There was an error fetching new suggestions.")
          }
          this.loadingSuggestions = false
        })
    },
    nextSuggestion(index) {
      index %= this.suggestions.length
      this.currentSuggestion = this.suggestions[index]
      setTimeout(() => this.nextSuggestion(index+1), this.suggestionCycleSeconds * 1000)
    },
    generateReport(){
      this.answerLoading = true
      this.errorMessage = ''
      this.answerExplanation = ''
      this.reportData = []
      this.currentPage = 1

      const data = {
        db_id: this.selectedDatabaseId,
        message: this.message,
      }

      this.secured.post('/db_chat/query', data)
        .then(response =>{
          const data = response.data
          if(data.error_message){
            this.errorMessage = data.error_message
          }
          else if(data.results_explanation) {
            this.answerExplanation = data.results_explanation
          }
          else if(data.results_data.length > 0){
            this.reportData = data.results_data
          }
          else{
            this.errorMessage = "I had some trouble fulfilling your request. Please try again or rephrase."
          }

          this.answerLoading = false
        })
        .catch(error => {
          this.answerLoading = false
          console.log(error)
        })
    },
   submitSuggestion(suggestion){
      this.message = suggestion
      this.generateReport()
    },
    downloadCsv() {
      if(this.reportData.length > 0){
        const data = this.reportData
        const headers = Object.keys(data[0])

        const csv = [
          headers.join(","), 
          ...data.map((obj) => headers.map((key) => obj[key]).join(",")), 
        ].join("\n");

        const filename = "ci_report.csv"

        const link = document.createElement("a")
        link.setAttribute("href", "data:text/csv;charset=utf-8," + encodeURIComponent(csv))
        link.setAttribute("download", filename)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    },
    copyEmails() {
      // const emailList = this.reportData.map((obj) => obj.email).join(',');

      // const downloadFile = (content, filename, contentType) => {
      //   const a = document.createElement('a');
      //   const file = new Blob([content], {type: contentType});
      //   a.href = URL.createObjectURL(file);
      //   a.download = filename;
      //   a.click();
      // };

      // downloadFile(emailList, 'ci_emails.txt', 'text/plain');
      const emailList = this.reportData.map((obj) => obj.email).join(',')
      navigator.clipboard.writeText(emailList).then(() => {
        this.emailsCopiedDismissCountDown = 3
      }, function() {
        console.error("Unable to copy text to clipboard.")
      });
    },
    emailsCopiedCountDownChanged(newCountDown){
      this.emailsCopiedDismissCountDown = newCountDown
    }
  }
})
</script>

<style scoped>
  .prompt-suggestion {
    font-style: italic;
    color: black;
  }

  .slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for <2.1.8 */ {
  /* transform: translateX(10px); */
  opacity: 0;
}
</style>